import React, { useState } from "react";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

const Landing = () => {
  const [certificateNumber, setCertificateNumber] = useState("");
  const [newBtn, setNewBtn] = useState("");
  const [pdfBlobUrlCertificate, setPdfBlobUrlCertificate] = useState("");
  const [pdfBlobUrlGWF, setPdfBlobUrlGWF] = useState("");

const handleFetchCertificate = async () => {
  try {
    const response = await axios.get(
      `https://uk-backend-oulb.onrender.com/api/pdf/${certificateNumber}`
    );

    if (response.data && response.data.url) {
      console.log("Response Data:", response.data);

      // Fetch the PDF data from the URL
      const pdfResponse = await axios.get(response.data.url, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });
      console.log("PDF Blob:", pdfBlob);

      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      console.log("PDF Blob URL:", pdfBlobUrl);

      // Open PDF in new tab
      window.open(pdfBlobUrl, "_blank");
    } else {
      throw new Error("Empty response data");
    }
  } catch (error) {
    console.error("Error fetching certificate:", error);
    alert("Failed to fetch certificate");
  }
};

  const handleFetchGWF = async () => {
    try {
      const response = await axios.get(
        `${process.env.API_URL}/resume/${newBtn}`,
        {
          responseType: "blob",
        }
      );

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);

        // Open PDF in new tab
        window.open(pdfBlobUrl, "_blank");
      } else {
        throw new Error("Empty response data");
      }
    } catch (error) {
      console.error("Error fetching GWF document:", error);
      alert("Failed to fetch GWF document");
    }
  };

  const handleDownloadPDF = (pdfUrl, fileName) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleConvertToPDF = () => {
    const input = document.getElementById("pdf-content");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };
  return (
    <>
      <header className="gem-c-layout-super-navigation-header" role="banner">
        <div className="govuk-width-container">
          <div className="gem-c-layout-super-navigation-header__header-logo">
            <a
              className="govuk-header__link govuk-header__link--homepage"
              href="/"
            >
              <span className="govuk-header__logotype">
                <svg
                  aria-hidden="true"
                  class="govuk-header__logotype-crown gem-c-layout-super-navigation-header__logotype-crown"
                  height="30"
                  width="36"
                  focusable="false"
                  viewBox="0 0 132 97"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25 30.2c3.5 1.5 7.7-.2 9.1-3.7 1.5-3.6-.2-7.8-3.9-9.2-3.6-1.4-7.6.3-9.1 3.9-1.4 3.5.3 7.5 3.9 9zM9 39.5c3.6 1.5 7.8-.2 9.2-3.7 1.5-3.6-.2-7.8-3.9-9.1-3.6-1.5-7.6.2-9.1 3.8-1.4 3.5.3 7.5 3.8 9zM4.4 57.2c3.5 1.5 7.7-.2 9.1-3.8 1.5-3.6-.2-7.7-3.9-9.1-3.5-1.5-7.6.3-9.1 3.8-1.4 3.5.3 7.6 3.9 9.1zm38.3-21.4c3.5 1.5 7.7-.2 9.1-3.8 1.5-3.6-.2-7.7-3.9-9.1-3.6-1.5-7.6.3-9.1 3.8-1.3 3.6.4 7.7 3.9 9.1zm64.4-5.6c-3.6 1.5-7.8-.2-9.1-3.7-1.5-3.6.2-7.8 3.8-9.2 3.6-1.4 7.7.3 9.2 3.9 1.3 3.5-.4 7.5-3.9 9zm15.9 9.3c-3.6 1.5-7.7-.2-9.1-3.7-1.5-3.6.2-7.8 3.7-9.1 3.6-1.5 7.7.2 9.2 3.8 1.5 3.5-.3 7.5-3.8 9zm4.7 17.7c-3.6 1.5-7.8-.2-9.2-3.8-1.5-3.6.2-7.7 3.9-9.1 3.6-1.5 7.7.3 9.2 3.8 1.3 3.5-.4 7.6-3.9 9.1zM89.3 35.8c-3.6 1.5-7.8-.2-9.2-3.8-1.4-3.6.2-7.7 3.9-9.1 3.6-1.5 7.7.3 9.2 3.8 1.4 3.6-.3 7.7-3.9 9.1zM69.7 17.7l8.9 4.7V9.3l-8.9 2.8c-.2-.3-.5-.6-.9-.9L72.4 0H59.6l3.5 11.2c-.3.3-.6.5-.9.9l-8.8-2.8v13.1l8.8-4.7c.3.3.6.7.9.9l-5 15.4v.1c-.2.8-.4 1.6-.4 2.4 0 4.1 3.1 7.5 7 8.1h.2c.3 0 .7.1 1 .1.4 0 .7 0 1-.1h.2c4-.6 7.1-4.1 7.1-8.1 0-.8-.1-1.7-.4-2.4V34l-5.1-15.4c.4-.2.7-.6 1-.9zM66 92.8c16.9 0 32.8 1.1 47.1 3.2 4-16.9 8.9-26.7 14-33.5l-9.6-3.4c1 4.9 1.1 7.2 0 10.2-1.5-1.4-3-4.3-4.2-8.7L108.6 76c2.8-2 5-3.2 7.5-3.3-4.4 9.4-10 11.9-13.6 11.2-4.3-.8-6.3-4.6-5.6-7.9 1-4.7 5.7-5.9 8-.5 4.3-8.7-3-11.4-7.6-8.8 7.1-7.2 7.9-13.5 2.1-21.1-8 6.1-8.1 12.3-4.5 20.8-4.7-5.4-12.1-2.5-9.5 6.2 3.4-5.2 7.9-2 7.2 3.1-.6 4.3-6.4 7.8-13.5 7.2-10.3-.9-10.9-8-11.2-13.8 2.5-.5 7.1 1.8 11 7.3L80.2 60c-4.1 4.4-8 5.3-12.3 5.4 1.4-4.4 8-11.6 8-11.6H55.5s6.4 7.2 7.9 11.6c-4.2-.1-8-1-12.3-5.4l1.4 16.4c3.9-5.5 8.5-7.7 10.9-7.3-.3 5.8-.9 12.8-11.1 13.8-7.2.6-12.9-2.9-13.5-7.2-.7-5 3.8-8.3 7.1-3.1 2.7-8.7-4.6-11.6-9.4-6.2 3.7-8.5 3.6-14.7-4.6-20.8-5.8 7.6-5 13.9 2.2 21.1-4.7-2.6-11.9.1-7.7 8.8 2.3-5.5 7.1-4.2 8.1.5.7 3.3-1.3 7.1-5.7 7.9-3.5.7-9-1.8-13.5-11.2 2.5.1 4.7 1.3 7.5 3.3l-4.7-15.4c-1.2 4.4-2.7 7.2-4.3 8.7-1.1-3-.9-5.3 0-10.2l-9.5 3.4c5 6.9 9.9 16.7 14 33.5 14.8-2.1 30.8-3.2 47.7-3.2z"
                    fill="currentColor"
                    fill-rule="evenodd"
                  ></path>
                </svg>{" "}
                <span
                  className="govuk-header__logotype-text"
                  style={{ fontSize: 28 }}
                >
                  CoS CHECK
                </span>
              </span>
            </a>
          </div>
        </div>
      </header>

      <div className="gem-c-layout-for-public__blue-bar govuk-width-container" />

      <main role="main" id="content" className="guide" lang="en">
        <div className="govuk-width-container">
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-thirds">
              <h1 className="gem-c-heading govuk-heading-l govuk-!-margin-bottom-6">
                View and prove documents for obtaining a UK visa
              </h1>
              <section className="intro">
                <h2 id="who-can-use-this-service">
                  Check your certificates of sponsorship
                </h2>
                <p>
                  If you were informed that your employer issued you a
                  certificate of sponsorship, then you can check it by entering
                  the CoS number of this document.
                </p>
                <p>
                  The Certificate of Sponsorship number is essential information
                  for work visa applications in the Worker and Temporary Worker
                  categories. A certificate of sponsorship (CoS) is issued by a
                  sponsor who is licensed to hire migrant workers. These
                  documents are essential for those who apply for work visas in
                  the UK.
                  <p>
                    Enter the CoS number of your sponsorship certificate in the
                    search box to check information about the availability of
                    the document. After confirmation, enter your passport number
                    for more information.
                  </p>
                </p>
                <div className="resume-fetch-container">
                  <div className="fetch-form">
                    <input
                      type="text"
                      value={certificateNumber}
                      onChange={(e) => setCertificateNumber(e.target.value)}
                      placeholder="Enter Certificate Number"
                      className="certificate"
                    />
                    <button
                      onClick={handleFetchCertificate}
                      className="fetch-button"
                    >
                      Check availability of invitation{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                  {pdfBlobUrlCertificate && (
                    <div className="pdf-container">
                      <iframe
                        src={pdfBlobUrlCertificate}
                        width="100%"
                        height="600px"
                        title="Certificate PDF"
                      ></iframe>
                      <button
                        id="download"
                        onClick={() =>
                          handleDownloadPDF(
                            pdfBlobUrlCertificate,
                            "certificate.pdf"
                          )
                        }
                      >
                        Download PDF
                      </button>
                    </div>
                  )}
                </div>

                <h2>Check your certificates of sponsorship by GWF number</h2>
                <p>
                  After submitting an online application for a UK visa, you can
                  check this document by number (GWF). Your GWF number is a
                  unique number that is assigned to your UK visa application.
                  The GWF number can be found on the visa application form
                  submitted.
                </p>
                <p>
                  Enter the GWF number of your visa application in the search
                  box to check information on document availability. Once
                  confirmed, enter your passport number for more information.
                </p>
                <div className="resume-fetch-container">
                  <div className="fetch-form">
                    <input
                      type="text"
                      value={newBtn}
                      onChange={(e) => setNewBtn(e.target.value)}
                      placeholder="Enter your GWF number"
                      className="certificate"
                    />
                    <button onClick={handleFetchGWF} className="fetch-button">
                      Check availability of invitation{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                  {pdfBlobUrlGWF && (
                    <div className="pdf-container">
                      <iframe
                        src={pdfBlobUrlGWF}
                        width="100%"
                        height="600px"
                        title="GWF Document PDF"
                      ></iframe>
                      <button
                        id="download"
                        onClick={() =>
                          handleDownloadPDF(
                            pdfBlobUrlCertificate,
                            "certificate.pdf"
                          )
                        }
                      >
                        Download PDF
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      <div class="gem-c-feedback__prompt-questions js-prompt-questions">
        <div class="gem-c-feedback__prompt-question-answer">
          <h2 class="gem-c-feedback__prompt-question">
            Is this service useful?
          </h2>
          <div className="btnn">
            <button className="yes">Yes</button>

            <button className="no">No</button>
          </div>
        </div>
      </div>
      <footer
        className="gem-c-layout-footer govuk-footer gem-c-layout-footer--border"
        role="contentinfo"
        data-module="ga4-link-tracker"
        data-ga4-link-tracker-module-started="true"
      >
        <div className="govuk-width-container">
          <hr className="govuk-footer__section-break" />
          <ul
            style={{ marginBottom: "75px" }}
            className="govuk-footer__inline-list govuk-!-display-none-print"
            data-module="gem-track-click"
            data-track-links-only=""
            data-gem-track-click-module-started="true"
          >
            <li className="govuk-footer__inline-list-item">
              <a
                style={{ color: "#1d70b8", fontWeight: "bold" }}
                className="govuk-footer__link"
                data-track-category="footerClicked"
                data-track-action="supportLink"
                data-track-label="/help"
                data-track-options='{ "dimension29": "Help" }'
                data-ga4-link='{ "event_name": "navigation", "type": "footer", "index": { "index_link": "1", "index_section": "3", "index_section_count": "5" }, "index_total": "8", "section": "Support links" }'
                href="/"
              >
                Home
              </a>
            </li>
            <li className="govuk-footer__inline-list-item">
              <a
                style={{ color: "#1d70b8", fontWeight: "bold" }}
                className="govuk-footer__link"
                data-track-category="footerClicked"
                data-track-action="supportLink"
                data-track-label="/help"
                data-track-options='{ "dimension29": "Help" }'
                data-ga4-link='{ "event_name": "navigation", "type": "footer", "index": { "index_link": "1", "index_section": "3", "index_section_count": "5" }, "index_total": "8", "section": "Support links" }'
                href="/certificates-of-sponsorship"
              >
                Certificates of sponsorship
              </a>
            </li>
            <li className="govuk-footer__inline-list-item">
              <a
                style={{ color: "#1d70b8", fontWeight: "bold" }}
                className="govuk-footer__link"
                data-track-category="footerClicked"
                data-track-action="supportLink"
                data-track-label="/help"
                data-track-options='{ "dimension29": "Help" }'
                data-ga4-link='{ "event_name": "navigation", "type": "footer", "index": { "index_link": "1", "index_section": "3", "index_section_count": "5" }, "index_total": "8", "section": "Support links" }'
                href="/contact-support"
              >
                Contact Support
              </a>
            </li>
            <li className="govuk-footer__inline-list-item">
              <a
                style={{ color: "#1d70b8", fontWeight: "bold" }}
                className="govuk-footer__link"
                data-track-category="footerClicked"
                data-track-action="supportLink"
                data-track-label="/help"
                data-track-options='{ "dimension29": "Help" }'
                data-ga4-link='{ "event_name": "navigation", "type": "footer", "index": { "index_link": "1", "index_section": "3", "index_section_count": "5" }, "index_total": "8", "section": "Support links" }'
                href="/check-invitation"
              >
                Check invitation
              </a>
            </li>
          </ul>
          <div className="govuk-footer__meta">
            <div className="govuk-footer__meta-item govuk-footer__meta-item--grow">
              <h2 className="govuk-visually-hidden">Support links</h2>

              <span
                className="govuk-footer__licence-description"
                data-module="gem-track-click"
                data-track-action="copyrightLink"
                data-track-category="footerClicked"
                data-track-label="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                data-track-options='{"dimension29": "Open Government Licence v3.0"}'
                data-track-links-only=""
                data-ga4-track-links-only=""
                data-ga4-link='{"event_name":"navigation","section":"Licence","index":{"index_section":"4","index_link":"1","index_section_count":"5"},"text":"Open Government Licence v3.0","index_total":"1","type":"footer"}'
                data-gem-track-click-module-started="true"
              >
                All content is available under the{" "}
                <a
                  className="govuk-footer__link"
                  href="/open-goverment-licence"
                  target="_blank"
                  rel="license"
                >
                  Open Government Licence v3.0
                </a>
                , except where otherwise stated
              </span>
              <a
                style={{ paddingTop: 25 }}
                className="govuk-footer__link govuk-footer__copyright-logo"
              >
                Copyright©2023 | All rights reserved
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Landing;
