import React from "react";

import Landing from "./Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FileUpload from "./UploadForm";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Landing />} />
          <Route path="/upload" element={<FileUpload />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
